@import '../../../design-system/color-palette/color-palette';
@import '../../../styles/functions';

.layer {
  padding: 1.5rem;
  margin: 3.5rem 1rem 0 1rem;
  .description {
    margin-top: 0.5rem;
    color: $brandcolor_primary;
  }
  .guideToApp {
    width: 100%;
  }
  .button {
    margin-top: 1.5rem;
  }
}
