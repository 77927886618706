@import 'src/styles/functions.scss';

// 로딩화면만 고정 높이를 주고 그 이후 화면은다 컨텐츠에 동적으로 높이를 정한
.loadingWrapper {
  height: 30.25rem;
}

.carInfoLoader {
  padding: 1.75rem 1.5rem 1rem 1.5rem;
  box-sizing: border-box;
  .title {
    margin-bottom: 4px;
    .emoji {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .loaderCarNumberGIF {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    .gif {
      width: 7.5rem;
      height: 7.5rem;
    }
  }

  .carInfoContainer {
    margin-top: 0.5rem;
    padding: 1.5rem 1rem;
    margin-bottom: 1rem;

    .nameInput {
      margin-top: 1rem;
      width: 100%;
      letter-spacing: 4px;
    }
  }
}

.hidden {
  position: absolute;
  top: 1.75rem;
  left: 1.5rem;
  right: 1.5rem;
  bottom: 1rem;
  width: calc(100% - 3rem);
  animation: fadeOut 0.5s;
  overflow: hidden;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    display: block;
  }
  30% {
    opacity: 0.3;
  }
  99% {
    height: 100%;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}

.show {
  animation: fadeIn 0.5s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
