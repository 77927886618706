@import 'src/styles/functions';
@import 'src/styles/mixin';
@import 'src/design-system/color-palette/color-palette';

.carousel {
  padding-right: getVw(100);
  margin-top: 2.5rem;
  @include pc {
    padding-right: getMaxVw(70);
  }
  :global {
    .swiper-container {
      overflow: visible !important;
    }
  }
  .card {
    z-index: 1;
    width: getVw(232);
    padding: 1rem;
    background: $base_white;
    .reviewImage {
      width: 100%;
    }
    .description {
      margin-top: 0.5rem;
      white-space: pre-line;
    }
    .car {
      display: inline-block;
      font-size: 0.75rem;
      line-height: 1.25rem;
      margin-top: 0.5rem;
      color: $base_gray6;
    }
    .footer {
      margin-top: 0.5rem;
      display: flex;
      justify-content: flex-end;
      .reviewLogo {
        width: 3.75rem;
        height: 1.25rem;
      }
    }
    @include pc {
      width: getMaxVw(232);
    }
  }
  .slide {
    width: 100%;
    box-shadow: -10px 10px 15px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    @include pc {
      box-shadow: -15px 15px 30px rgba(0, 0, 0, 0.15);
    }
  }
}
