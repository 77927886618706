@import 'src/styles/variables';

@mixin pc {
  @media (min-width: #{$pc}) {
    @content;
  }
}

@mixin animationHide {
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
  transform: translateY(40px);
  opacity: 0;
}
@mixin animationView {
  transform: translateY(0) !important;
  opacity: 1 !important;
}
