@import 'src/styles/variables';
@import 'src/design-system/color-palette/color-palette';

.toast {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 80vw;
  max-width: 22.5rem;
  color: $base_white;
  padding: 0.875rem 0.75rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem $base_8;
  display: flex;
  align-items: center;
  z-index: $z-index-toast;
  white-space: pre-line;
  line-height: 1.5;
}

.icon {
  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  min-height: 1rem;
  margin-right: 0.25rem;
  fill: $base_white;
}

.success {
  background-color: $success_primary;
}
.fail {
  background-color: $warning_primary;
}
