@import '../color-palette/color-palette.scss';

.typography {
  font-stretch: normal;
  letter-spacing: normal;
  color: #272e40;
  margin: 0;
  display: block;
}

.Headline_1 {
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 4rem;
}

.Headline_32 {
  font-weight: bold;
  font-size: 2rem;
  line-height: 3.25rem;
}

.Headline_24 {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2.5rem;
}

.Headline_24_L {
  font-size: 1.5rem;
  line-height: 2.5rem;
}

.Subtitle_20 {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 2rem;
}

.Subtitle_1 {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 2rem;
}


.Subtitle_16 {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.75rem;
}

.Subtitle_14 {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.75rem;
}

.Subtitle_12 {
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.Subtitle_3 {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5rem;
}


.Body_1 {
  font-size: 1rem;
  line-height: 1.75rem;
}

.Body_14 {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.Caption {
  font-size: 0.75rem;
  line-height: 1.25rem;
}
