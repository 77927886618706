@import 'src/design-system/color-palette/color-palette';
@import 'src/styles/variables';
@import 'src/styles/functions';

.layout {
  position: relative;
  padding: 3.75rem 2.75rem;
  max-width: 640px;
  margin: 0 auto;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: $base_gray2;

  @media (min-width: $pc) {
    border: solid 1px $base_gray3;
    box-shadow: 0 2px 4px $base_8;
    padding: 3.75rem 2.75rem;
  }
  @media (max-height: 640px) and (orientation: landscape) {
    width: 420px;
  }
}
