@import 'src/design-system/color-palette/color-palette';
@import 'src/styles/variables';
@import 'src/styles/functions';
@import 'src/styles/mixin';

.carNumberForm {
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transition-delay: 0.3s;
  opacity: 0;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(40px);
  width: 100%;
  max-width: 640px;
  background-color: $base_gray2;
  box-sizing: border-box;
  padding: 1rem 1.25rem 1.25rem 1.25rem;
  box-shadow: 0 -16px 32px rgba(39, 46, 64, 0.08);
  border-radius: 16px 16px 0px 0px;

  z-index: $z-index-carNumberForm;

  @media (max-height: 640px) and (orientation: landscape) {
    width: 420px;
    height: 18.25rem;
  }
  .emoji {
    margin-right: 4px;
  }

  .carNumberInputWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
  }

  .button {
    word-break: keep-all;
    margin-left: 0.5rem;
  }
}

.view {
  transform: translateY(0) translateX(-50%);
  opacity: 1;
}
