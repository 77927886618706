@import 'src/styles/variables';
@import 'src/design-system/color-palette/color-palette';

.calendar {
  margin-bottom: 1.5rem;
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
    .month {
      margin: 0 1rem;
    }
    .iconWrapper {
      padding: 0.75rem;
      cursor: pointer;
      .dateArrowIcon {
        fill: $base_gray4;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .weekdays {
    display: flex;
    justify-content: space-around;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    .weekday {
      color: $base_gray6;
      font-size: 0.875rem;
      height: 1.5rem;
      line-height: 1.5rem;
    }
  }
  .days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(5, 1fr);
    border-top: 1px solid $base_gray3;
    border-left: 1px solid $base_gray3;
    .day {
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid $base_gray3;
      border-bottom: 1px solid $base_gray3;
      color: $base_gray6;
      font-size: 0.875rem;
      cursor: pointer;
    }
    .isToday {
      color: $brandcolor_primary;
    }
    .isSelected {
      background-color: $brandcolor_primary;
      color: $base_white;
    }
    .disable {
      cursor: auto;
      color: $base_gray4;
      background-color: $base_gray2;
    }
    .today {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .todayText {
        font-size: 0.625rem;
      }
    }
  }
}
