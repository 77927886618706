@import 'src/design-system/color-palette/color-palette';

.container {
  margin-top: 1.5rem;
  .label {
    color: $base_gray6;
    margin-bottom: 0.5rem;
  }
  .timetable {
    &:first-child {
      margin-bottom: 0.5rem;
    }
    .times {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 0.5rem;
      .time {
        border: 1px solid $base_gray3;
        box-shadow: 0 2px 4px $base_8;
        border-radius: 4px;
        padding: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .disable  {
        background-color: $base_gray1;
        color: $base_gray4;
        cursor: auto;
      }
      .selected {
        background-color: $brandcolor_primary;
        color: $base_white;
      }
    }
  }
}
