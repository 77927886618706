@import 'src/design-system/color-palette/color-palette';
@import 'src/styles/animations';

.layout {
  padding: 3.75rem 0 2.25rem 0;
  .section1 {
    .title {
      transition: opacity 0.3s ease-in-out;
      padding: 0 2.5rem;
      opacity: 0;

    }
    .standardList {
      padding: 0 2.5rem;
      .listItem {
        font-size: 0.875rem;
        line-height: 1.5rem;
        display: flex;
        align-items: center;
      }
      .pass {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
        fill: $brandcolor_primary;
      }
      .failed {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
        fill: $warning_primary;
      }
    }
    .info {
      transition: opacity 0.3s ease-in-out;
      opacity: 0;
      padding: 0.75rem 1rem;
      margin: 1rem 2.5rem 0 2.5rem;
      background: $base_gray3;
      border-radius: 8px;
    }
    .free {
      color: $brandcolor_primary;
    }
  }


  .section2 {
    transition: opacity 0.5s ease-in-out;
    transition-delay: 0.5s;
    opacity: 0;
  }

  .layer {
    margin: 0 1rem 2.25rem 1rem;
  }
}

.view {
  opacity: 1 !important;
}

.viewSection2 {
  opacity: 1 !important;
}
