@import 'src/design-system/color-palette/color-palette.scss';
@import 'src/styles/functions.scss';

.wrapper {
  border: 1px solid $base_gray3;
  padding: 1rem;
  box-sizing: border-box;

  .header {
    margin-bottom: 0.5rem;
  }

  .row {
    display: flex;
    justify-content: space-between;
  }

  .label {
    display: flex;
    align-items: center;
    min-width: 3.75rem;
    margin-right: 0.5rem;
    word-break: keep-all;
  }

  .modalName {
    text-align: left;
    word-break: break-all;
  }

  .emoji {
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 4px;
  }
}
