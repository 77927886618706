@import 'src/design-system/color-palette/color-palette';
@import 'src/styles/mixin';

.section4 {
  @include animationHide();
  padding: 2.5rem 2rem;
  background: $base_gray2;
  overflow: hidden;
  .zero {
    color: $brandcolor_primary;
  }
}

.view {
  @include animationView();
}
