@import 'src/design-system/color-palette/color-palette';

.popupContent {
  // Todo 추후 BottomPopup 코드 수정 후 !important 제거
  .intro {
    padding: 1.375rem 1.5rem 0.875rem 1.5rem;
    background-color: $base_white;
    .titleWrapper {
      display: flex;
      justify-content: space-between;
    }
  }
  .progress {
    height: 75vh;
    overflow: auto;
  }
}
