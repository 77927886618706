@import 'src/design-system/color-palette/color-palette';

.placeholder {
  user-select: none;
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  .hangle {
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 2px;
    margin-left: 4px;
    color: $base_gray3;
    text-shadow: none;
  }
  .number {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 1px;
    color: $base_gray3;
    text-shadow: none;
  }

  .isCompletedHangle {
    margin-right: 8px;
  }
}
