@import 'src/design-system/color-palette/color-palette';
@import 'src/styles/functions';
@import 'src/styles/mixin';
@import 'src/styles/animations';

.section1 {
  position: relative;
  .section1Image {
    animation: fadeinUp 0.3s ease-in-out;
    width: 100%;
    height: getVw(440);
    @include pc {
      height: getMaxVw(440);
    }
  }
  .mouse {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    bottom: 1rem;
  }
  .ball {
    animation: fadeOutDown infinite 1.2s;
  }
  .arrow {
    animation: fadeOutDownSmall infinite 1.2s;
  }
  .content {
    position: absolute;
    left: 2rem;
    top: 3.75rem;
    .title {
      color: $base_white;
      animation: fadeinUp 0.3s ease-in-out;
      animation-delay: 0.3s;
      animation-fill-mode: backwards;
    }
    .description {
      margin-top: 0.5rem;
      color: $base_white;
      animation: fadeinUp 0.3s ease-in-out;
      animation-delay: 0.5s;
      animation-fill-mode: backwards;
    }
  }
}

@keyframes fadeOutDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(3px);
  }
}


@keyframes fadeOutDownSmall {
  from {
    opacity: 0;
    transform: translateY(0);
  }
  to {
    opacity: 1;
    transform: translateY(2px);
  }
}
