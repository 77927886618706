@import '../../../design-system/color-palette/color-palette';
@import '../../../styles/functions';

.layer {
  padding: 1.5rem 0;
  margin: 3.5rem 1rem 0 1rem;
  .content {
    padding: 0 1.5rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.625rem;
      .titleWrapper {
        display: flex;
        align-items: center;
        .emoji {
          width: 1rem;
          height: 1rem;
          margin-right: 0.5rem;
        }
      }
      .button {
        width: 100%;
        margin-top: 1.5rem;
      }
    }

    .reservationInputWrapper {
      margin-top: 0.75rem;
    }
  }

  .checkboxWrapper {
    display: flex;
    align-items: center;
    width: calc(100% - 3rem);
    margin: 0 auto;

    .checkbox {
      margin: 0.625rem 0;
      padding: 0;
    }
    .privacyPolicy {
      margin-left: 4px;
      color: $base_gray6;
      cursor: pointer;
    }
  }
  .border {
    border-bottom: 1px solid $base_gray3;
    margin-top: 1rem;
  }

  .button {
    width: calc(100% - 3rem);
    margin: 0 auto;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    fill: $base_white;
  }
  .disableIcon {
    fill: $base_gray4;
  }
}


.arrowIcon {
  fill: $base_gray4;
  width: 16px;
  height: 16px;
}
