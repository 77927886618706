@import 'src/styles/variables';
@import 'src/design-system/color-palette/color-palette';

.modal {
  box-sizing: border-box;
  padding: 32px;
  width: calc(100vw - 2rem);
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  @media (min-width: $pc) {
    width: calc(640px - 2rem);
  }
  @media (max-height: 640px) and (orientation: landscape) {
    width: 420px;
  }
  .subTitleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 0;
    cursor: pointer;
    .subTitle {
      display: flex;
      align-items: center;
      .icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;
      }
    }
    .arrowIcon {
      fill: $base_gray4;
      transition: 0.3s ease-in-out;
    }

    .isOpen {
      transform: rotate(-180deg);
    }

    .active {
      color: $brandcolor_primary;
    }
  }
  .border {
    border-bottom: 1px solid $base_gray3;
  }
  .calendarWrapper {
    transition: opacity 0.3s ease-in-out;
  }
}
