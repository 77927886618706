@import 'src/styles/variables';

.dimmer {
  z-index: 99;
  max-width: $pc;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background-color: white;
  min-width: 100px;
  min-height: 100px;
}
