@import '../../design-system/color-palette/color-palette.scss';

.container {
  position: relative;

  .hiddenInput {
    width: 10px;
    position: absolute;
    left: -1000%;
    border: none;
    color: transparent;
    caret-color: transparent;
  }
}
