@import 'src/design-system/color-palette/color-palette';
@import 'src/styles/functions';
@import 'src/styles/mixin';

.section3 {
  padding: 2.5rem 2rem;
  background: $base_gray2;
  border-bottom: 1px solid $base_gray3;
  .title {
    margin-bottom: 2.5rem;
  }
  .process {
    @include animationHide();
    &:not(:last-child) {
      margin-bottom: 4.75rem;
    }

    .imageWrapper {
      text-align: center;
      margin-top: 1.5rem;
      .processImg {
        width: 100%;
      }
    }
    .subTitle {
      margin-top: 2rem;
      .order {
        color: $base_white;
        background: $brandcolor_primary;
        border-radius: 2px;
        padding: 0 0.5rem;
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 1.5rem;
        margin-right: 0.5rem;
      }
      display: flex;
      align-items: center;
    }
    .description {
      margin-top: 0.5rem;
      white-space: pre-line;
    }
  }
}



.view {
  @include animationView();
}

.animationDisable {
  @include animationView();
}
