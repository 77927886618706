@import 'src/design-system/color-palette/color-palette';

.input {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  padding: 14px;
  background-color: $base_white;
  border: 1px solid $base_gray3;
  box-shadow: 0 2px 4px $base_8;
  outline: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  transition: 0.2s all;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  .icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }
  .placeholder {
    color: $base_gray4;
  }
}
.container {
  padding-top: 10px;
}

.hiddenInput {
  width: 10px;
  position: absolute;
  left: -1000%;
  border: none;
  color: transparent;
  caret-color: transparent;
}

.focus {
  border-color: $brandcolor_primary;
}
