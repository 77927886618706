@import 'src/design-system/color-palette/color-palette.scss';

.container {
  width: 170px;
  box-sizing: border-box;
  padding: 4px 6px;
  flex-direction: column;
  color: white;
  background-color: #257a57;
  border-radius: 10px;
  border: 3px solid black;

  .row {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .regionWrapper {
    display: flex;
    .region {
      font-size: 20px;
      font-weight: bold;
      text-shadow: rgba($base_white, 0.5) 0 1px 1px;
    }
    margin-right: 6px;
  }

  .headNumber {
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.6;
    text-shadow: rgba($base_white, 0.5) 0 1px 1px;
  }

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    background-color: #2d956d;
    vertical-align: middle;
    box-shadow: 0 0 1px 1px rgba(55, 55, 55, 0.35);
    text-shadow: rgba(#272e40, 0.2) 1px 1px 1px;
  }
  .leftDot {
    margin-right: 10px;
  }
  .rightDot {
    margin-left: 10px;
  }

  .border {
    border-radius: 8px;
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
    box-shadow: black 0 0 0 2px inset;
  }

  .middleHangle {
    font-size: 28px;
    font-weight: bold;
    text-shadow: rgba($base_white, 0.5) 1px 1px 1px;
  }

  .carNumber {
    display: flex;
    height: 44px;
    align-items: center;
    position: relative;
    .cursor {
      display: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 80%;
      width: 4px;
      background-color: $base_white;
    }
    .tail {
      font-size: 32px;
      font-weight: bold;
      letter-spacing: 1px;
      text-shadow: rgba($base_white, 0.5) 1px 1px 1px;
    }
    .isFocused {
      display: block;
    }
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
