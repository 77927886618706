.diplomacyPopup {
  .content {
    padding: 2rem 2rem 1rem 2rem;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    .cancelButton {
      margin-right: 1rem;
    }
    .icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }
  }
}
