@import 'src/styles/mixin';
@import 'src/design-system/color-palette/color-palette';

.section6 {
  padding: 2.5rem 1.5rem;
  background: $base_white;
  @include animationHide();
  .faq {
    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }
    .question {
      margin-bottom: 0.5rem;
      white-space: pre-wrap;
    }
    .answer {
      white-space: pre-wrap;
    }

    .smallLogo {
      margin-right: 0.5rem;
    }
    .buttonWrapper {
      margin-top: 1rem;
      overflow: hidden;
    }
  }
}

.view {
  @include animationView();
}
