@import '../../../color-palette/color-palette.scss';

.wrapper {
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  background-color: $base_white;
  border-color: $base_gray3;
  cursor: pointer;
  transform: translateX(-12px);
  transition: 0.2s all;
  -webkit-tap-highlight-color: transparent;

  &.big {
    padding: 16px;
  }
  &.small {
    padding: 12px 16px;
  }
  &.list {
    background-color: transparent !important;
    border-width: 0 !important;
  }
  &.box {
    border-width: 1px;
    border-style: solid;
  }

  .checkboxWrapper {
    padding: 12px;
  }
  .checkbox {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    border-color: $base_gray4;
    background-color: $base_white;
    transition: 0.1s all;
  }

  &:hover {
    border-color: $base_gray6;
    background-color: $base_gray1;
    .checkbox {
      border-color: $base_gray6;
      background-color: $base_gray1;
    }
  }
  &:focus-visible, &:focus {
    border-color: $brandcolor_primary;
    background-color: $base_white;
    .checkbox {
      border-color: $brandcolor_primary;
      background-color: $base_white;
    }
  }
  &.checked {
    border-color: $brandcolor_primary;
    background-color: $brandcolor_8;
    .checkbox {
      border-color: $brandcolor_primary;
      background-color: $brandcolor_primary;
    }
  }
  &.warning {
    border-color: $warning_primary;
    background-color: $base_white;
    &.checked {
      background-color: $warning_8;
      .checkbox {
        border-color: $warning_primary;
        background-color: $warning_primary;
      }
    }
    .checkbox {
      border-color: $warning_primary;
      background-color: $base_white;
    }
  }
  &.disabled {
    border-color: $base_gray3;
    background-color: $base_gray1;
    .checkbox {
      border-color: $base_gray3;
      background-color: $base_gray2;
    }
  }

  .checkIcon {
    width: 1rem;
    height: 1rem;
  }
}

.disabledTypo {
  color: $base_gray4;
}
