@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@import './styles/nomalize.css';
@import './styles/variables';
@import 'src/design-system/color-palette/color-palette';

* {
  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
}

html {
  @media (min-width: $pc) {
    font-size: 22px;
  }
}

body {
  background-color: $base_gray2;
  padding-bottom: env(safe-area-inset-bottom); // IOS 11.0 이상 (신)
  padding-bottom: constant(safe-area-inset-bottom); // IOS 11.0 버전 (구)
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
}
