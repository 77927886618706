@import '../../design-system/color-palette/color-palette.scss';
@import 'src/styles/variables';

.dimmer {
  width: 100vw;
  max-width: $pc;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  z-index: $z-index-dimmer;
  transition: height;
  &.open {
    background-color: $base_32;
    height: 100vh;
    transition: height 0.01s, background-color 0.5s;
  }
  &.close {
    background-color: transparent;
    height: 0;
    transition: height 0.3s, background-color 0.05s;
  }
  @media (max-height: 640px) and (orientation: landscape) {
    width: 420px;
  }
  .wrapper {
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    transition: height 0.2s linear, opacity 0.3s;
    background-color: $base_gray2;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
    .closeIconWrap {
      position: absolute;
      top: 0.75rem;
      right: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3rem;
      width: 3rem;
      .closeIcon {
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
      }
    }
    .content {
      position: relative;
    }
  }
}

.noTransition {
  transition: none !important;
}

.caption {
  text-align: center;
  color: $brandcolor_primary;
}

.button {
  margin-top: 2.5rem;
  width: 100%;
}
