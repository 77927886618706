.wrapper {
  height: 20rem;
  padding: 1.75rem 1.5rem 1rem 1.5rem;
  box-sizing: border-box;
  transition: height 0.15s ease-in-out;

  .content {
    margin-top: 0.25rem;
    margin-bottom: 2rem;
    .emoji {
      width: 1rem;
      height: 1rem;
      margin-right: 4px;
    }
  }

  .inputs {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .inputButton {
    border: none;
    word-break: keep-all;
  }
  .codeInput {
    margin-top: 0.75rem;
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
  }
  .isVisibleCodeInput {
    opacity: 1;
  }
}

.isCodeSend {
  height: 24.25rem;
}
