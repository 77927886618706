@import 'src/design-system/color-palette/color-palette';
@import 'src/styles/variables';
@import 'src/styles/functions';

.home {
  padding: 0;
  background: $base_white;
  padding-bottom: 134px;
  background: $base_gray2;
  .content {
  }
}
