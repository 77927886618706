.rippleParent {
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.rippleSpan {
  width: 10px;
  height: 10px;
  margin-top: -10px;
  margin-left: -10px;
  position: absolute;
  display: inline-block;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);

  animation: ripple 0.9s;
  opacity: 0;
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(10);
  }
}

.dummy {
  display: none;
}
