@import 'src/design-system/color-palette/color-palette';

.footer {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1.5rem 2.5rem 1.5rem;
  background-color: $base_gray2;
  height: 5rem;
  .content {
    margin-top: 0.5rem;
  }
  .privacyPolicy {
    cursor: pointer;
  }
  .info {
    color: $base_gray6;
    overflow: hidden;
  }
}
