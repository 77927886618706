@import 'src/design-system/color-palette/color-palette';
@import 'src/styles/mixin';
.section2 {
  background: $base_white;
  padding-bottom: 2.5rem;
  .description {
    padding: 2rem 2rem 0 2rem;
    .subTitle {
      margin-bottom: 16px;
    }
  }
  .freeInfo {
    @include animationHide();
    text-align: center;
    background: $base_gray2;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
    margin: 1rem 1.5rem 0 1.5rem;
    .emoji {
      display: inline-block;
    }
    .priceWrapper {
      display: flex;
      align-items: center;
      .originalPrice {
        font-size: 1rem;
        line-height: 1.75rem;
        margin-right: 0.5rem;
        text-decoration: line-through;
      }
      .salePriceWrapper {
        display: flex;
        align-items: center;
        .salePrice {
          display: inline;
          color: $brandcolor_primary;
        }
      }
    }
  }
}

.view {
  @include animationView();
}
