@import 'src/design-system/color-palette/color-palette.scss';
@import 'src/styles/functions.scss';
@import 'src/styles/variables';

.layout {
  padding: 1.25rem 0 0 0;
  min-height: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  .result {
    flex: 1;
    padding: 0 2.5rem;
    margin-bottom: 1.5rem;
    .passIcon {
      margin-bottom: 0.5rem;
      width: 3.75rem;
      height: 3.75rem;
    }
    .content {
      margin: 8px 0;
    }
  }

  .installApp {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5rem;
    background-color: $base_white;
    border-radius: 1rem 1rem 0 0;
    animation: fadein 0.8s ease-in-out;

    .imageWrapper {
      height: 100%;
      .mockupInstallApp {
        margin-top: 1.5rem;
        background-image: url("../../images/mockup_install_app.png");
        background-size: contain;
        background-position:  center bottom;
        background-repeat: no-repeat;
        background-color: $base_gray3;
        height: calc(100% - 6.5rem);
      }
      .button {
        margin-top: 1.5rem;
      }
      .icon {
        margin-right: 0.5rem;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .emoji {
    width: 0.875rem;
    height: 0.875rem;
    margin-right: 0.5rem;
  }
}

$offset: 500;

.check path {
  fill: none;
  stroke: #22e2ac;
  stroke-miterlimit: 10;
  stroke-width: 41px;
  stroke-dasharray: $offset;
  stroke-dashoffset: $offset;
  stroke-linejoin: round;
  transform: rotate(18deg);
  animation: draw 0.5s linear forwards;
}

.check {
  width: 3.5rem;
  height: 1.8rem;
  margin-bottom: 1.56rem;
}

@keyframes draw {
  from {
    stroke-linecap: round;
  }
  to {
    stroke-linecap: round;
    stroke-dashoffset: 0;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
