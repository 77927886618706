@import '../color-palette/color-palette.scss';

.button {
  // defaults
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: 0.2s all;
  -webkit-tap-highlight-color: transparent;

  // size
  &.big {
    font-size: 1rem;
    padding: 1rem;
    line-height: 1.5rem;
  }

  &.medium {
    font-size: 0.875rem;
    padding: 1rem;
    line-height: 1rem;
  }

  &.tiny {
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 0.5rem;
  }

  // color
  &.primary-brandcolor {
    background-color: $brandcolor_primary;
    box-shadow: 0 2px 4px $brandcolor_32;
    color: $base_white;
    border: none;

    @media (hover: hover) {
      &:hover {
        background-color: $brandcolor_light;
        box-shadow: 0 2px 4px $brandcolor_32;
      }
    }

    &:active {
      background-color: $brandcolor_dark;
      box-shadow: none;
    }
  }

  &.secondary-black {
    background-color: $base_white;
    border: 1px solid $base_gray3;

    box-shadow: 0 2px 4px rgba(39, 46, 64, 0.08);
    border-radius: 4px;
  }

  &.text-brandcolor {
    // text-brandcolor는 disabled 상태에도 배경색이 없게 한다.
    background-color: transparent !important;
    color: $brandcolor_primary;
    border: none;
  }

  &.text-gray {
    background-color: $base_white;
    border: 1px solid $base_gray3;
    box-shadow: 0 2px 4px rgba(39, 46, 64, 0.08);
    color: $base_gray6;
  }

  .marginRight {
    display: inline;
    margin-right: 4px;
  }
  .marginLeft {
    display: inline;
    margin-left: 4px;
  }

  &.disabled {
    border: 1px solid $base_gray3;
    color: $base_gray4 !important;
    background-color: $base_gray2;
    box-shadow: none !important;
    cursor: auto;

    @media (hover: hover) {
      &:hover {
        background-color: $base_gray2;
      }
    }
  }
}
