@import 'src/design-system/color-palette/color-palette.scss';

.container {
  border-radius: 8px;
  position: relative;
  width: 230px;
  height: 60px;
  box-shadow: 2px 2px 5px 2px rgba(230, 230, 230, 1);
  box-sizing: border-box;

  .carNumberInput {
    border-radius: 8px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: background-color 0.15s ease-in-out;
    background-color: $base_white;
  }
  .business {
    background-color: #f0b218;
  }
  .border {
    border-radius: 8px;
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
    box-shadow: black 0 0 0 2px inset;
  }
  .carNumber {
    display: flex;
    height: 44px;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    .cursor {
      display: none;
      position: absolute;
      width: 4px;
      background-color: $brandcolor_primary;
    }
    .isFocused {
      display: block;
    }
    .oldCarNumberWrapper {
      display: flex;
      .vertical {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .smallHangle {
          font-size: 24px;
          display: inline-block;
          height: 27.5px;
          font-weight: bold;
          letter-spacing: 2px;
          margin-right: 4px;
          text-shadow: rgba(#b1b3bb, 0.5) 1px 1px 1px, rgba(#b1b3bb, 0.5) 1px 1px 1px;
        }
      }
    }
    .horizontal {
      display: flex;
      align-items: center;
    }
  }

  .dot {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    display: inline-block;
    background-color: rgba(#e9edf4, 0.5);
    vertical-align: middle;
    box-shadow: 2px 2px 2px 0 rgba(218, 221, 227, 1);
    top: 50%;
    transform: translateY(-50%);
  }
  .leftDot {
    position: absolute;
    left: 12px;
  }
  .rightDot {
    position: absolute;
    right: 12px;
  }
  .businessDot {
    box-shadow: 0 1px 2px 0 rgba(65, 77, 107, 0.6);
  }

  .hangle {
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 1px;
    margin-left: 4px;
    text-shadow: rgba(#b1b3bb, 0.5) 1px 1px 1px, rgba(#b1b3bb, 0.5) 1px 1px 1px;
  }

  .isCompletedHangle {
    margin-right: 8px;
  }
  .number {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 1px;
    text-shadow: #b1b3bb 1px 1px 1px;
  }
}

:global(.blinkAnimation) {
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
