@import 'src/design-system/color-palette/color-palette';
@import 'src/styles/mixin';

.section5 {
  padding: 2.5rem 2rem;
  background: $base_white;
  @include animationHide();
  .zero {
    color: $brandcolor_primary;
  }
  .content {
    display: flex;
    border: 1px solid $base_gray3;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-top: 1.5rem;
    .column {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      .icPass {
        margin-right: 7px;
      }
      .originalPrice {
        color: $base_gray6;
        text-decoration: line-through;
        margin-right: 0.5rem;
      }
      .free {
        font-weight: bold;
        color: $brandcolor_primary;
      }
    }
    .left {
      flex: 1;
    }
    .right {
      flex: 1;
      border: 1px solid $brandcolor_primary;
      border-radius: 4px;
    }
  }
  .info {
    margin-top: 1.5rem;
    color: $base_gray6;
  }
}


.view {
  @include animationView();
}
